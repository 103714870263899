<template>
  <div
    v-if="reviewResultHasData" 
    class="mx-8 my-10"
  >
    <p
      class="text-sm text-[#253754F2_!important] font-semibold text-center"
    >
      {{ $t("mainmenu.review.title") }}
    </p>

    <div class="w-full mt-6 mb-3">
      <NuxtImg
        loading="lazy"
        alt="refer a friend illustration"
        src="/img/Refer-a-friend-illustration-2.svg"
        class="md:h-16"
        width="290"
        height="160"
      />
    </div>

    <div class="flex justify-center mt-2.5 mb-4">
      <CommonStarsRating
        class="text-sm"
        :value="review.rating"
      />
    </div>

    <p class="text-sm text-space-cadet leading-5">
      <span>{{ review.review }}</span><br>
      <span class="font-semibold">{{ review.customer_name }}, {{ review.location }}</span>
    </p>

    <NuxtLinkLocale 
      :locale="switchBlogLocale()" 
      to="/reviews" 
      class="flex mt-6"
      v-on:click.prevent="handleMenuRedirect"
    >
      <BaseButton
        :text="$t('mainmenu.review.button')"
        class="w-full"
      ></BaseButton>
    </NuxtLinkLocale>
  </div>
</template>

<script setup lang="ts">

const review = ref({});

const { data: reviewResult } = await useBlockReview(1);

const reviewResultHasData = ref(false);
if (reviewResult.value.data.length > 0) {
  reviewResultHasData.value = true;
  review.value =  reviewResult.value.data[0];
}

</script>
