<template>
  <div class="bg-grey-light flex justify-between text-dark-blue flex-wrap">
    <div class="w-1/2 py-4 px-6 font-semibold border-b border-gray-300">
      <span>{{ $t("common.country") }}</span>
    </div>
    <div class="w-1/2 py-4 px-6 font-semibold border-b border-gray-300">
      <span>{{ $t("common.language") }}</span>
    </div>
    <div class="flex w-full justify-between">
      <div
        class="w-1/2 bg-white py-2 border-r-4 border-grey-light md:h-[calc(100vh-100px)] h-[70vh] overflow-scroll overflow-x-hidden"
        :class="{
          'h-[calc(100vh-100px)]': position == 'footer',
          'md:h-[calc(100vh-100px)] h-[70vh]': position != 'footer',
        }"
      >
        <div class="w-full" v-for="(site, key) in visibleSites" :key="key">
          <div class="w-full text-start" v-if="site.external_link == false">
            <input
              :id="`site-${key}`"
              type="radio"
              :value="site.name"
              hidden
              v-model="country"
              class="peer/internal text-dark-blue"
              @change="changeLocale(site.languages)"
              :checked="activeSite.name == site.name"
            />
            <label
              :for="`site-${key}`"
              class="px-6 py-3 flex cursor-pointer peer-checked/internal:bg-primary-light peer-checked/internal:font-semibold hover:bg-primary-light/5 items-center text-[17px] text-sm font-medium text-gray-900"
              :class="{ 'bg-primary-light font-semibold': selectedCountry == site.name }"
            >
              <MenuLanguageFlag :item="site" />
              {{ site.name }}
            </label>
          </div>
          <!-- TODO: REMOVE AFTER LANG SWITCHER IS FULLY FUNCTIONAL -->
          <div class="w-full text-start" v-else>
            <NuxtLinkLocale
              :locale="switchBlogLocale()"
              :to="site.external_link"
            >
              <input
                :id="`site-${key}`"
                type="radio"
                :value="site.name"
                hidden
                v-model="country"
                class="peer text-dark-blue"
                @change.prevent="switchExternalSite(site)"
                :checked="country == site.name"
              />
              <label
                :for="`site-${key}`"
                class="px-6 py-3 flex cursor-pointer hover:bg-primary-light/5 items-center text-[17px] text-sm font-medium text-gray-900"
              >
                <MenuLanguageFlag :item="site" />
                {{ site.name }}
                <NuxtImg
                  loading="lazy"
                  src="/icons/launch.svg"
                  alt="launch"
                  class="ml-auto"
                  width="12"
                  height="12"
                />
              </label>
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
      <div class="w-1/2 bg-white pt-2">
        <div class="w-full" v-for="(site, key2) in visibleSites" :key="key2">
          <div 
            v-if="site.external_link == false"
            v-for="(language, key) in site.languages" :key="key"
            class="w-full"
            :class="{'hidden': selectedCountry != site.name}"
          >
            <NuxtLinkLocale 
              :to="getLangUrl(language.code)"
              @click.prevent="changeLocale([language])"
              class="px-6 py-3 flex cursor-pointer peer-checked:bg-primary-light peer-checked:font-semibold hover:bg-primary-light/5 items-center text-[17px] text-sm font-medium text-gray-900"
              :class="{ 'bg-primary-light font-semibold': language.code == locale }"
            >
              {{ language.title }}
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useGlobalStore } from "@/stores/global";
import { regexps } from "~/utils/regexps";
const globalStore = useGlobalStore();

const props = defineProps({
  allSites: Object,
  position: {
    type: String,
    default: '',
  },
});

const country = ref("");
const getCode = ref("");
const { locale, setLocale, setLocaleCookie } = useI18n();

const visibleSites = computed(() => Object.fromEntries(Object.entries(props.allSites).filter(([, site]) => site.visibility !== false)))

const activeSite = computed(() => globalStore.siteCurrentLocaleDetail);

const emit = defineEmits(["closeSlider"]);
const switchLocalePath = useSwitchLocalePath();

const securelySwitchLocalePath = async (locale) => {
  const { path } = useRoute();
  const resolvedPath =
    path
      .split("/")
      .filter((x) => !regexps.localeCode.test(x))
      .filter((x) => !regexps.localeLangCode.test(x))
      .join("/") || "/";


  if(path.includes('/blog')) {
    return await navigateTo({ path: switchLocalePath(resolvedPath, useGlobalStore().siteLangCode) }) 
   }
    const routeUrl = localeUrlQuery("/", locale);
    const preferedCountry = useCookie(
    'preferredCountry',
     {
      maxAge: 30*86400*2,
      watch: true
      }
    )
    preferedCountry.value = locale
    
    reloadNuxtApp({
    path: routeUrl.fullPath+ '/', ttl: 500, force: true
  });
   // return navigateTo(routeUrl.fullPath+ '/', { redirectCode: 301 })
};

//  REMOVE AFTER LANG SWITCHER IS FULLY FUNCTIONAL
async function switchExternalSite(countrySelected) {
  if (countrySelected.external_link == false) {
    getCode.value = countrySelected.languages[0].code;
    await securelySwitchLocalePath(getCode.value);
    emit("closeSlider");
  } else {
    await callSwitchApi('external', countrySelected.name);
    return navigateTo(countrySelected.external_link, { external: true });
  }
}

async function changeLocale(countrySelected) {
  if (countrySelected.length == 1) {
    getCode.value = countrySelected[0].code;
    await securelySwitchLocalePath(getCode.value);
    globalStore.setCountrySwitcherVisibility(false);
    emit("closeSlider");
  }
}

const generateRandomString = () => {
  return Math.floor(Math.random() * Date.now()).toString(36);
};

const counter = ref(generateRandomString());

async function callSwitchApi(dataType, country) {
  await useSiteSwitching({
    identifier: counter.value,
    type: dataType,
    country: country
  });
}

// const checked = computed(() => {
//   return Object.values(props.allSites).find((data) => data.languages.find(
//       (langCode) => langCode.code == locale.value
//     ))
// });
const selectedCountry = computed(() => {
  return country.value  ? country.value : activeSite.value.name;
});

const runtimeConfig = useRuntimeConfig();
const getLangUrl = (code) => {
  return runtimeConfig.public.i18n?.baseUrl + '/' + code
}

watch(
  () => globalStore.siteCountrySwitcherVisibility,
  () => {
    country.value = '';
  },
);
</script>
