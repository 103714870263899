<template>
  <div 
    v-if="showMenuSwitcher"
    class="fixed top-0 left-0 bg-black/30 opacity-0 transition-all duration-500 ease-in-out"
    :class="{ 'w-full h-full opacity-100': globalStore.siteCountrySwitcherVisibility == true }"
    @click="closeLanguages"
  >
    <div 
      class="country-selector fixed top-0 -right-[520px] h-full transition-all duration-500 ease-in z-[10]"
      :class="{ 'open': globalStore.siteCountrySwitcherVisibility == true }"
      @click.stop="disableSwitcherClick"
    >
      <div class="absolute right-0 m-2 flex">
        <button @click="closeLanguages" type="button"
          class="z-50 text-gray-400 bg-light-silver rounded-full fill-secondary p-1">
          <svg aria-hidden="true" class="w-5 h-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <div class="bg-white">
        <MenuLanguage :allSites="allSites" :position="position" />
      </div>
      <div
        class="border-t-2 border-light-silver w-full bg-grey-light absolute bottom-0 p-3 flex justify-start text-secondary underline font-bold cursor-pointer"
      >
        <NuxtLink @click="closeLanguages"> {{ $t("common.cancel") }} </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  allSites: Object,
  position: {
    type: String,
    default: '',
  },
});

const showMenuSwitcher = computed(() => {
  return Object.keys(props.allSites).length > 1;
});

const globalStore = useGlobalStore();

function closeLanguages() {
  globalStore.setCountrySwitcherVisibility(false);
}

function disableSwitcherClick() {
  
}

watch(
  () => globalStore.siteCountrySwitcherVisibility,
  () => {
    if (globalStore.siteCountrySwitcherVisibility) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  },
);

onMounted(() => {
  // IP location for language
  // if (navigator.geolocation && !useCookie('i18n_redirected').value) {
  //   navigator.geolocation.getCurrentPosition(async function(position) {
  //     if(position) {
  //     const data  = await globalStore.getPosition(position);
  //     await navigateTo({ path: switchLocalePath(data) })
  //     }
  //   });
  // }
});
</script>

<style scope>
.country-selector.open {
  @apply right-0; 
}
</style>
