<template>
  <li
    class="grow-0 flex items-center"
    :data-megamenu-anchor-key="props.anchorKey"
    :data-active="isActive"
  >
    <NuxtLinkLocale
      :locale="switchBlogLocale()"
      :to="props.to"
      :class="props.class"
    >
      <slot />
      <NuxtImg
        loading="lazy"
        v-if="!!props.anchorKey"
        src="/icons/ctrl-down.svg"
        alt="down arrow icon"
        class="megamenu-anchor__indicator"
        width="13"
        height="13"
      />
    </NuxtLinkLocale>
  </li>
</template>

<script setup lang="ts">
import type { LooseAutoComplete } from "~/types/general";
import type { MegaMenuAnchorKey } from "./index.vue";
import { useMegaMenuStore } from "./store";

const megamenuStore = useMegaMenuStore();

export interface MegaMenuAnchorProps {
  to: string;
  anchorKey?: LooseAutoComplete<MegaMenuAnchorKey>;
  class?: string;
}

const props = withDefaults(defineProps<MegaMenuAnchorProps>(), {});

const isActive = computed(
  () => !!props.anchorKey && megamenuStore?.activeSection === props.anchorKey
);

watch(isActive, (src) => {
  const megamenuElement = document.querySelector<HTMLElement>(".megamenu");
  if (!src || !megamenuElement) return;

  megamenuElement.setAttribute("data-is-active", String(true));
});
</script>

<!-- Mega Menu reference styles -->
<style scoped lang="scss">
header li {
  @apply relative transition-opacity border-y-4 border-transparent;

  & > a {
    @apply flex flex-row items-center;
  }

  .megamenu-anchor__indicator {
    @apply text-secondary transition-transform relative align-middle inline-block aspect-square shrink-0 ml-1;
    width: 0.75em;
    margin-top: 0.3em;
    transform: rotateX(0deg);
  }

  &[data-active="true"],
  &:hover,
  &:focus-within {
    @apply border-b-secondary-2;

    .megamenu-anchor__indicator {
      transform: rotateX(180deg);
    }

    a {
      @apply text-secondary-2;
    }
  }
}
</style>
