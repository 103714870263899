<template>

    <div class="bg-secondary h-1 w-0 rounded-r-lg transition-all duration-200 ease-out" id="myBar" :style="{ width: progress }" ></div>
 
</template>
<script setup>

const progress = ref('0%');

function getElementHeight(type, ele) {
   let element = document.getElementById(ele);
   
   if (element) {
      return element.clientHeight;
   }

   return 0;
}

function updateProgressIndicator() {
   let displayProgressbar = document.getElementById("display-progressbar");
   let width = 0;
   if (displayProgressbar) {
      const { documentElement, body } = document;
      let windowScroll = body.scrollTop || documentElement.scrollTop;

      let header = getElementHeight("id", "header");
      let breadcrumbs = getElementHeight("id", "breadcrumbs");
      let pageContent = getElementHeight("id", "page-content");

      let height = header + breadcrumbs + pageContent;
      let ratio = ((windowScroll / height) < 1) ? (windowScroll / height) : 1;
      width = `${Math.round(ratio * 100)}%`;
   }
   progress.value = width; 
}

onMounted(() => {
   window.addEventListener("scroll", updateProgressIndicator);
})

</script>
