<template>
  <NuxtLinkLocale
    v-if="props.as === 'RippleBox'"
    :locale="switchBlogLocale()"
    :to="props.href"
    v-on:click.prevent="handleMenuRedirect"
    class="megamenu-item__anchor max-w-full"
  >
    <CommonRippleBox
      class="megamenu-item overflow-hidden py-6 px-8 gap-4 bg-[#F7F7F7] hover:text-white focus-visible:text-white"
      ripple-position="center-left"
      tabindex="0"
      :data-type="props.as"
    >
      <NuxtImg
        loading="lazy"
        :src="`/icons/${asRippleBoxIcon ?? 'woman-man_purple.svg'}`"
        alt="ripplebox-icon"
        class="w-[1em] mt-[.3585em] aspect-square mb-auto flex-shrink-0"
        width="16"
        height="16"
      />
      <div class="overflow-hidden">
        <BaseTypography class="text-[15px] font-semibold1">{{
          props.title
        }}</BaseTypography>
        <BaseTypography
          v-if="!!props.description"
          class="text-sm font-normal"
          >{{ props.description }}</BaseTypography
        >
      </div>
      <div
        class="w-8 aspect-square rounded-full ml-auto bg-white text-secondary flex-shrink-0 grid place-items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.8"
          stroke="currentColor"
          class="w-4 flex-shrink-0 aspect-square ml-[2.75px]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
    </CommonRippleBox>
  </NuxtLinkLocale>

  <div v-else class="megamenu-item w-fit overflow-hidden pb-4 gap-2">
    <NuxtImg
      loading="lazy"
      :src="`/icons/${icon ?? 'arrow-right-secondary.svg'}`"
      alt=""
      class="megamenu-item__icon min-w-[.65em] max-w-[0.85em] mt-[.3125em] aspect-square mb-auto shrink-0"
      width="10"
      height="10"
    />

    <CommonCallNumber
      tag="div"
      v-if="props.href == 'call'"
      callClass="font-semibold"
      headerClass="min-w-[12rem] hover:underline text-[15px] font-normal text-secondary"
      :keyPath="props.title"
    />

    <NuxtLinkLocale
      v-else
      :locale="switchBlogLocale()"
      :to="props.href"
      v-on:click.prevent="handleMenuRedirect"
      tabindex="0"
      class="megamenu-item__anchor w-fit text-[#21375680] hover:text-[#213756BA] cursor-pointer"
    >
      <BaseTypography
        v-html="props.title"
        class="megamenu-item__anchor-title text-sm font-semibold text-space-cadet"
      />
      <BaseTypography
        v-if="!!props.description"
        class="megamenu-item__anchor-desc text-xs font-normal mt-1 text-[#213756BA]"
        disable-ellipsis
        :style="{
          'text-decoration': 'none !important',
        }"
        >{{ props.description }}</BaseTypography
      >
    </NuxtLinkLocale>
  </div>
</template>

<script setup lang="ts">
import { useMegaMenuStore } from "./store";

export interface MegaMenuItemProps {
  title: string;
  href?: string;
  description?: string;
  as?: "RippleBox" | "div";
  icon?: string;
  asRippleBoxIcon?: string;
}

const props = withDefaults(defineProps<MegaMenuItemProps>(), {
  as: "div",
  href: "/",
});
</script>

<style scoped lang="scss">
.megamenu-item {
  
}

.megamenu-item__anchor {
  @apply no-underline;

  .megamenu-item__anchor-title {
    @apply min-w-[3em];
  }

  &:not(:hover) .megamenu-item__icon {
  }

  .megamenu-item__icon {
  }

  &:hover,
  &:focus-visible {
    .megamenu-item__anchor-title, .megamenu-item__anchor-desc {
      @apply text-secondary-2;
    }
  }
}
</style>
