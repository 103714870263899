<template>
  <div
    class="mx-8 my-10"
  >
    <p
      class="text-sm text-[#253754F2_!important] font-semibold text-center"
    >
      {{ $t("mainmenu.blog.title") }}
    </p>

    <div class="w-full mt-6 mb-3">
       <NuxtLinkLocale 
        :locale="switchBlogLocale(true)" 
        :to="(slug)"
        v-on:click.prevent="handleMenuRedirect"
      >
        <NuxtImg
         loading="lazy"
          class="object-cover rounded-[px] w-full max-h-40"
          :src="blog.image.img_large"
          width="190"
        />
      </NuxtLinkLocale>
    </div>

    <NuxtLinkLocale 
      :locale="switchBlogLocale(true)" 
      :to="(slug)" 
      class="text-secondary-2 font-semibold"
      v-on:click.prevent="handleMenuRedirect"
    >
      {{ blog.title }}
    </NuxtLinkLocale>

    <NuxtLinkLocale 
      :locale="switchBlogLocale(true)" 
      to="/blog" 
      class="flex mt-12"
      v-on:click.prevent="handleMenuRedirect"
    >
      <BaseButton
        :text="$t('mainmenu.blog.button')"
        class="w-full"
      ></BaseButton>
    </NuxtLinkLocale>
  </div>
</template>

<script setup lang="ts">

const blog = ref({})

const { data: blogLists, pending, error: errorMessage, refresh } = await useBlogLatest();
blog.value =  blogLists.value.data;
const slug = `/blog/${blog.value.slug}`;
</script>
