<template>
  <LayoutMegaMenuCollection
    @active-section-changed="handleActiveSectionChanged"
  >
    <LayoutMegaMenuTests :active-section="(megamenuStore.activeSection as MegaMenuAnchorKey)" />
    <LayoutMegaMenuHelpAndAdvice :active-section="(megamenuStore.activeSection as MegaMenuAnchorKey)" />
  </LayoutMegaMenuCollection>
</template>

<script lang="ts" generic>
// You can actually declare new sections here
export const megamenuSections = {
  tests: {
    categories: [],
  },
  "help-and-advice": {
    categories: ["sexual-health-and-stis", "support-faqs"],
  },
} as const;

export type MegaMenuAnchorKey = keyof typeof megamenuSections;
export type MegaMenuCategoryAnchorKey<
  TSectionName extends MegaMenuAnchorKey = MegaMenuAnchorKey
> = (typeof megamenuSections)[TSectionName]["categories"][number];
</script>

<script setup lang="ts">
import { useMegaMenuStore } from "./store";

const megamenuStore = useMegaMenuStore();

const handleActiveSectionChanged = (newActiveSection?: MegaMenuAnchorKey) => {
  megamenuStore.setActiveSection(newActiveSection);
};
</script>
