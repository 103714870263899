<template>
  <div class="relative" v-if="showMenuSwitcher">
    <transition
      :name="nextAction ? 'slide-fade-in' : 'slide-fade-out'"
      mode="in-out"
    >
      <div class="">
        <div class="py-4">
          <div class="flex justify-between items-center space-x-2">
            <div
              @click="openLanguages()"
              class="w-full flex items-center font-medium text-dark-blue cursor-pointer"
            >
              <NuxtImg
                loading="lazy"
                :src="`/icons/flags/${globalStore.siteCurrentLocaleDetail?.flag}`"
                :alt="getLangValue"
                class="mx-1 w-6 h-6"
                :class="{
                  'mr-3': position == 'footer',
                }"
                height="32"
                width="32"
              />
              <span v-if="position == 'footer'" class="text-[#991E66] underline"> {{ globalStore.siteCurrentLocaleDetail?.name }} | {{ getLangValue }}</span>
              <span v-else class="uppercase font-bold"> {{ getLangValue }}</span>
              <NuxtImg
                v-if="position != 'footer'"
                loading="lazy"
                src="/icons/ctrl-down.svg"
                class="w-3 h-3 mx-1"
                aria-hidden="true"
                alt=""
                height="32"
                width="32"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { useGlobalStore } from "@/stores/global";

const nextAction = ref(false);
const props = defineProps({
  closeMenu: Function,
  allSites: Object,
  position: {
    type: String,
    default: '',
  },
});

const showMenuSwitcher = computed(() => {
  return Object.keys(props.allSites).length > 1;
});

const globalStore = useGlobalStore();

function openLanguages() {
  globalStore.setCountrySwitcherVisibility(true);
}

const getLangValue = computed(() => {
  if (Object.keys(globalStore.siteCurrentLocaleDetail).length > 0) {
    let country = globalStore.siteCurrentLocaleDetail.languages.find(
      (data) => data.code == globalStore.siteCurrentLocale
    )

    return (props.position == 'footer') ? country?.title : country?.lang;
  }
  return "";
});

onMounted(() => {
  // IP location for language
  // if (navigator.geolocation && !useCookie('i18n_redirected').value) {
  //   navigator.geolocation.getCurrentPosition(async function(position) {
  //     if(position) {
  //     const data  = await globalStore.getPosition(position);
  //     await navigateTo({ path: switchLocalePath(data) })
  //     }
  //   });
  // }
});
</script>

<style scope>
.slide-fade-in-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-in-enter-from,
.slide-fade-in-leave-to {
  transform: translateX(300px);
  opacity: 0;
}

.slide-fade-out-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-out-enter-from,
.slide-fade-out-leave-to {
  transform: translateX(-300px);
  opacity: 0;
}
</style>
