<template>
  <div
    class="megamenu-item pt-11 pb-10 px-4 gap-4 max-w-full overflow-[visible_!important]"
    tabindex="0"
    :data-active="isActive"
    @pointerover="handleOnHover"
  >
    <NuxtImg
      loading="lazy"
      :src="`/icons/${props.icon ?? 'woman-man_purple.svg'}`"
      alt=""
      class="category-item__icon w-[1em] mt-[.3585em] aspect-square mb-auto flex-shrink-0"
      width="16"
      height="16"
    />
    <div class="overflow-hidden">
      <BaseTypography class="text-[15px] font-semibold text-[#213756]">{{
        props.title
      }}</BaseTypography>
      <BaseTypography
        v-if="!!props.description"
        disable-ellipsis
        class="text-xs font-normal mt-1"
        >{{ props.description }}</BaseTypography
      >
    </div>
    <div
      class="chevron-icon transition-transform w-8 aspect-square rounded-full ml-auto bg-[#CEEDED] text-[#267476] flex-shrink-0 grid place-items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2.8"
        stroke="currentColor"
        class="w-4 flex-shrink-0 aspect-square ml-[2.75px]"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MegaMenuCategoryAnchorKey } from "./index.vue";

export interface MegaMenuCategoryAnchorProps {
  categoryAnchor: MegaMenuCategoryAnchorKey;
  activeCategoryAnchor: MegaMenuCategoryAnchorKey;
  title: string;
  description?: string;
  onActivated?: (anchor: MegaMenuCategoryAnchorProps["categoryAnchor"]) => void;
  icon?: string;
}

const props = withDefaults(defineProps<MegaMenuCategoryAnchorProps>(), {});

const isActive = computed<boolean>(
  () => props.categoryAnchor === props.activeCategoryAnchor
);
const handleOnHover = () => {
  props.onActivated?.(props.categoryAnchor);
};
</script>

<style scoped lang="scss">
.megamenu-item {
  @apply cursor-pointer text-[#21375680] sticky top-0 transition-colors;

  &[data-active="true"] {
    @apply bg-white text-[#213756BA] z-1;

    .chevron-icon {
      @apply bg-[#267476] translate-x-full text-white;
    }
  }
  &[data-active="false"] {
    filter: grayscale(1) opacity(0.85);
  }
}
</style>
