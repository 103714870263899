<template>
  <BaseTypography
    class="flex gap-2.5 font-semibold text-secondary-2 text-sm tracking-[.39px] px-3.5 pt-4 pb-6 mx-2.5 border-t-2 border-[#25375480]"
    disable-ellipsis
  >
    <NuxtImg
      loading="lazy"
      src="/icons/arrow-right-secondary.svg"
      alt=""
      class="megamenu-item__icon min-w-[.65em] max-w-[0.85em] mt-[.4125em] aspect-square mb-auto flex-shrink-0"
      width="10"
      height="10"
    />
    <slot />
  </BaseTypography>
</template>
