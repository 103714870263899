<template>
  <div
    class="megamenu-category"
    :data-anchor-key="props.anchorKey"
    :data-active="isActive"
  >
    <slot v-if="!!$slots.title" name="title" />
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { MegaMenuCategoryAnchorKey } from "./index.vue";

export interface MegaMenuCategoryProps {
  anchorKey: MegaMenuCategoryAnchorKey;
  activeKey?: MegaMenuCategoryAnchorKey;
}

const props = withDefaults(defineProps<MegaMenuCategoryProps>(), {});

const isActive = computed<boolean>(() => props.anchorKey === props.activeKey);
</script>

<style scoped lang="scss">
.megamenu-category {
  @apply transition-opacity pt-11 px-9 duration-500 row-span-1 col-span-1 row-start-1 col-start-1 auto-rows-max h-fit sticky top-0 flex flex-col h-full;

  &:not([data-active="true"]) {
    @apply opacity-0 pointer-events-none;
  }
}
</style>
