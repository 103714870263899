<template>
  <div class="menu bg-grey-light relative">
    <Transition
      :name="`slide-fade-${transitionDirection}`"
      mode="in-out"
    >
      <div v-if="canDisplayTree">
        <div
          v-if="activeTree.href !== '/'"
          @click="gotoPreviousMenu"
          class="p-4 bg-white flex items-center font-semibold border-b-2 text-dark-blue duration-700 ease-in-out"
        >
          <ChevronLeftIcon class="w-6 h-6" />
          <span>{{ activeTree.title }}</span>
        </div>

        <div
          :class="{
            'px-11 bg-grey-light overflow-y-auto  h-[calc(100vh-125px)]':
              !activeTree.customDrawerKey && activeTree.href !== '/',
          }"
        >
          <div
            v-if="!!activeTree.items"
            v-for="item in activeTree.items"
            :key="item.title"
            :class="activeTree.href !== '/' ? 'py-4' : 'bg-white'"
          >
            <a
              :href="_buildLocalisedLink(item.href)"
              @click.prevent.stop="navigateToMenu(item, $event)"
              class="flex justify-between items-center [&>*]:pointer-events-none"
            >
              <MenuItem
                :label="item.title"
                :expandable="!!item.items?.length"
                :class="
                  activeTree.href === '/'
                    ? 'header-menu-item'
                    : 'w-full flex justify-between items-center'
                "
              />
            </a>
          </div>
        </div>

        <div v-if="activeTree.href === '/'" class="p-4 bg-grey-light">
          <div class="flex items-center font-medium text-dark-blue">
            <NuxtLink
              class="flex"
              :to="globalStore.patientLoginLink"
              target="_blank"
            >
              <NuxtImg
                loading="lazy"
                src="/icons/profile.svg"
                alt="person"
                class="mr-[18px]"
              />
              <span>{{ $t("headerSideBar.loginLabel") }}</span>
            </NuxtLink>
          </div>

          <div
            class="flex justify-between items-center mt-8"
            v-if="!router.path.includes('/blog') && showMenuSwitcher"
          >
            <div
              @click="openLanguages()"
              class="w-full flex items-center font-medium text-dark-blue"
            >
              <NuxtImg
                loading="lazy"
                :src="`/icons/flags/${globalStore.siteCurrentLocaleDetail.flag}`"
                :alt="getLangValue"
                class="ml-1 mr-[18px] w-5 h-5"
                height="32"
                width="32"
              />
              <span class="uppercase font-bold"> {{ getLangValue }}</span>
            </div>
            <ChevronRightIcon class="w-6 h-6" />
          </div>
        </div>
      </div>
    </Transition>

    <Transition
      :name="`slide-fade-${transitionDirection}`"
      mode="in-out"
    >
      <div v-if="activeTree.customDrawerKey === 'languageSelector'">
        <MenuLanguage :allSites="allSites" @closeSlider="closeModal" />
      </div>
    </Transition>

    <div
      v-if="activeTree.href === '/'"
      class="absolute w-full bg-grey-light mb-10"
    >
      <CommonContactExpert />
    </div>
  </div>
</template>
<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  closeMenu: Function,
  allSites: Object,
  isOpen: Boolean,
});

const router = useRoute();
const transitionDirection = ref('in');
const globalStore = useGlobalStore();
const { t } = useI18n();

const testMenu = ref({});
const helpMenu = ref({});
const canDisplayTree = ref(true);
const menuTree = ref({
  title: "",
  href: "/",
  items: [
    {
      title: t("mainmenu.getStarted"),
      href: "/get-started",
    },
    {
      title: t("mainmenu.clinics"),
      href: "/clinics",
    },
    {
      title: t("mainmenu.tests"),
      href: "/tests",
      items: testMenu,
    },
    {
      title: t("mainmenu.helpAndAdvice"),
      href: "/help-and-advice",
      items: helpMenu,
    },
  ],
});
const menuTreeHistory = ref([menuTree.value]);
const activeTree = computed(() => menuTreeHistory.value.at(-1));

const showMenuSwitcher = computed(() => Object.keys(props.allSites).length > 1);

const closeModal = () => {
  const splicedHistory = [...menuTreeHistory.value];
  splicedHistory.length = 1;
  menuTreeHistory.menu = splicedHistory;

  props.closeMenu();
};

const getLangValue = computed(() => {
  return globalStore.siteCurrentLocaleDetail?.languages?.find(
    (data) => data.code == globalStore.siteCurrentLocale
  )?.["lang"];
});

const navigateToMenu = async (item, e) => {
  if (!item.items && !item.customDrawerKey && !!e?.target) {
    closeModal();
    await navigateTo(e.target.pathname);
    return;
  }

  menuTreeHistory.value = [...menuTreeHistory.value, item];
};

const gotoPreviousMenu = async () => {
  const splicedHistory = [...menuTreeHistory.value];
  splicedHistory.length -= 1;

  menuTreeHistory.value = splicedHistory;
};

function openLanguages() {
  navigateToMenu({
    title: t("headerSideBar.selectCountryAndLanguage"),
    href: "#language-selector",
    customDrawerKey: "languageSelector",
  });
}

async function _requestTransition(direction) {
  canDisplayTree.value = false;
  await new Promise((resolve) => setTimeout(resolve, 5));
  transitionDirection.value = direction;
  canDisplayTree.value = true;
}

function _buildLocalisedLink(href) {
  const locale = switchBlogLocale();
  const sanitizedHref = href.startsWith('/') ? href.substring(1) : href;
  const url = ['', locale, sanitizedHref].join('/');
  return url;
}

testMenu.value = globalStore.siteisAustralia
        ? globalStore.siteMobileTestMenu.filter((data) => data.showAustralia)
        : globalStore.siteMobileTestMenu;
const addSubcategoriesToHealthAndWellness = (menu) => {
  menu.forEach((item) => {
    if (item.href === "/tests/health-and-wellness") {
      item.items = [...item.items, ...globalStore.sitetestHealthSubMenu];
    }
  });
};

if (!globalStore.siteSettings.categories.includes('health-and-wellness')) {
  testMenu.value = testMenu.value.filter(item => item.href !== "/tests/health-and-wellness");
}
addSubcategoriesToHealthAndWellness(testMenu.value);

helpMenu.value = [
  {
    title: `<b>${t("mainmenu.overview")}</b>`,
    href: "/help-and-advice",
  },
  {
    title: t("mainmenu.helpAndAdviceMenu.sexualHealth.conditions"),
    href: "/help-and-advice/sexual-health-and-stis",
    items: globalStore.sitehelpMenu,
  },
];

if (globalStore.siteSettings.categories.includes('health-and-wellness')) {

  const helpHealthSubMenu = globalStore.sitehelpHealthSubMenu;

  helpHealthSubMenu.unshift({
    title: "<b>" + useTranslateStore("mainmenu.overview") + "</b>",
    href: '/help-and-advice/health-and-wellness',
  });

  // Add the third item
  helpMenu.value.push({
    title: t("mainmenu.helpAndAdviceMenu.healthAndWellness.topics"),
    href: "/help-and-advice/health-and-wellness",
    items: helpHealthSubMenu,
  });
}

helpMenu.value.push({
  title: t("mainmenu.faqs"),
  href: "/faqs",
});


watch(menuTreeHistory, async (src, prev) => {
  const direction = src.length > prev.length ? 'in' : 'out';
  await _requestTransition(direction);
})
</script>

<style scope>
.slide-fade-in-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-in-enter-from,
.slide-fade-in-leave-to {
  transform: translateX(300px);
  opacity: 0;
}

.slide-fade-out-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-out-enter-from,
.slide-fade-out-leave-to {
  transform: translateX(-300px);
  opacity: 0;
}
</style>
