<template>
  <div>
    <div
      class="megamenu"
      :data-should-slide="shouldSlide"
      :data-is-active="computedActiveSection !== undefined"
    >
      <div
        class="megamenu-container-wrapper md:max-w-7xl mx-auto"
      >
        <nav
          class="megamenu-container"
          @pointerenter="handlePointerEnter"
          @pointerleave="handlePointerLeave"
          
        >
          <div
            class="megamenu-arrow hidden"
            v-if="computedActiveSection !== undefined"
          ></div>

          <!-- MegaMenu wrapper -->
          <div
            class="megamenu-wrapper"
            :style="{
              '--width': activeSectionBounds?.width,
              '--height': activeSectionBounds?.height,
            }"
          ></div>

          <!-- MegaMenu sections -->
          <slot />
        </nav>
      </div>
    </div>
    <div class="megamenu-backdrop bg-dark-blue bg-opacity-60 transition-opacity"
      :data-is-active="computedActiveSection !== undefined"
    >
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MegaMenuAnchorKey } from "./index.vue";
import { megamenuSections } from "./index.vue";

const TIMEOUT_DELAY: number = 325; // Timeout delay(in milliseconds): MouseLeave duration to trigger close effect.

export type MegaMenuCollectionEmits = (
  eventName: "activeSectionChanged",
  activeSection: MegaMenuAnchorKey | undefined
) => void;

const emits = defineEmits<MegaMenuCollectionEmits>();

interface StyleRectPosition {
  "--left": DOMRect["left"];
  "--top": DOMRect["top"];
  "--anchor-left": DOMRect["left"];
  "--anchor-top": DOMRect["top"];
}

const hoveredAnchor = ref<MegaMenuAnchorKey>();
const activeSection = ref<typeof hoveredAnchor.value>();
const computedActiveSection = computed(
  () => activeSection.value ?? hoveredAnchor.value
);

const shouldSlide = ref<boolean>(false);
watch(computedActiveSection, (newState, oldState) => {
  shouldSlide.value = oldState !== undefined && newState !== undefined;
});

let lastHoverTime: number = new Date().getTime();

onMounted(() => {
  nextTick(() => {
    const header = document.querySelector("header");
    if (!header) return;

    // Let's find all the li's including data-megamenu-anchor-key
    const anchors = header.querySelectorAll<HTMLLIElement>(
      "li[data-megamenu-anchor-key]"
    );

    // We going to loop through each one of them, and add two custom event listeners
    anchors.forEach((item) => {
      // onMouseEnter(CSS:hover) - We want to set `megaMenuActiveSection.value` to this li's index.
      item.addEventListener("mouseenter", () => {
        const anchorKey = item.getAttribute("data-megamenu-anchor-key");
        const idx = Object.entries(megamenuSections).findIndex(
          ([x]) => x === anchorKey
        );
        if (!anchorKey || idx === -1) return;

        hoveredAnchor.value = anchorKey as MegaMenuAnchorKey;
        lastHoverTime = new Date().getTime();
      });

      // onMouseLeave(CSS:not(:hover)) - We want wait for a short amount of time and then reset `megaMenuActiveSection.value` to undefined.
      item.addEventListener("mouseleave", () =>
        setTimeout(() => {
          if (new Date().getTime() - lastHoverTime > TIMEOUT_DELAY)
            hoveredAnchor.value = undefined;
        }, TIMEOUT_DELAY)
      );
    });
  });
});

// Event Handlers
const handlePointerEnter = () => (activeSection.value = hoveredAnchor.value);
const handlePointerLeave = () => (activeSection.value = undefined);

// Watchers
watch(computedActiveSection, (source) => {
  emits("activeSectionChanged", source);
});

// Computeds
interface ComputedBoundingClientRect extends DOMRect {
  computedPosition: {
    left: number;
    top: number;
  };
}

interface InteractiveBoundingClientRect extends DOMRect {
  interactionPoint: {
    x: number;
    y: number;
  };
}
const activeSectionBounds = computed<null | ComputedBoundingClientRect>(() => {
  const targetIdx = computedActiveSection.value;
  if (targetIdx === undefined) return null;

  const container = document.querySelector<HTMLDivElement>(
    ".megamenu-container"
  );
  if (!container) return null;

  const containerBounds: DOMRect = container.getBoundingClientRect();
  const sectionBounds: DOMRect | undefined = container
    .querySelector<HTMLDivElement>(
      `.megamenu-section[data-anchor-key="${computedActiveSection.value}"]`
    )
    ?.getBoundingClientRect();

  if (!sectionBounds) return null;
  return {
    ...sectionBounds.toJSON(),
    computedPosition: {
      left: sectionBounds.left - containerBounds.left,
      top: sectionBounds.top - containerBounds.top,
    },
  };
});

const computedContainerPosition = useEffect<
  StyleRectPosition | undefined
>(() => {
  // Let's start by getting the anchor
  const anchor = document.querySelector<HTMLLIElement>(
    `header li[data-megamenu-anchor-key="${computedActiveSection.value}"]`
  );

  // Guard in case the anchor was manually deleted
  // Guard if active section bounds are broken
  const wrapperBounds: DOMRect | null = activeSectionBounds?.value;
  if (!anchor || !wrapperBounds) return undefined;

  // Get classic BoundingClientRect of the anchor
  const anchorRect: DOMRect = anchor.getBoundingClientRect();

  // Let's calculate the InteractiveBoundingClientRect
  const interactionRect: InteractiveBoundingClientRect = {
    ...anchorRect.toJSON(),
    interactionPoint: {
      x: anchorRect.x + anchorRect.width / 2, // We want the interaction to be at the middle of the X axis.
      y: anchorRect.y + anchorRect.height, // We want the interaction to be at the very bottom of the Y axis.
    },
  };

  // Now we need to make sure that the megamenu doesn't overflow out of the viewport
  // First, let's define the viewport size
  const viewportBounds = {
    width: document.documentElement.offsetWidth || window.innerWidth,
    height: document.documentElement.offsetHeight || window.innerHeight,
  };

  // Then, let's calculate the positionning points
  const computedInteractionPoints: Pick<DOMRect, "x" | "y"> & {
    overflows: { x: boolean; y: boolean };
  } = {
    x: interactionRect.interactionPoint.x - wrapperBounds.width / 2,
    y: interactionRect.interactionPoint.y,
    get overflows() {
      // We are going to make sure to declare overflows
      return {
        x: this.x + wrapperBounds.width > viewportBounds.width,
        y: this.y + wrapperBounds.height > viewportBounds.height,
      };
    },
  };

  // Finally let's set a fallback position to handle overflows
  const fallbackPosition: Pick<DOMRect, "x" | "y"> = {
    x: viewportBounds.width - wrapperBounds.width,
    y: viewportBounds.height - wrapperBounds.height,
  };

  // And then return the points depending on the previous checks
  return {
    "--left": !computedInteractionPoints.overflows.x
      ? computedInteractionPoints.x
      : fallbackPosition.x,
    "--top": !computedInteractionPoints.overflows.y
      ? computedInteractionPoints.y
      : fallbackPosition.y,
    get ["--anchor-left"]() {
      return interactionRect.interactionPoint.x - this["--left"];
    },
    get ["--anchor-top"]() {
      return interactionRect.interactionPoint.y - this["--top"];
    },
  };
}, [activeSectionBounds]);
</script>

<style lang="scss">
.__use-megamenu-inTransition {
  transition: all 450ms ease;
}
.__use-megamenu-outTransition {
  transition: all 250ms ease;
}
.megamenu {
  @apply absolute top-0 z-[55] p-4 w-full isolate overflow-hidden pointer-events-none;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &:not([data-is-active="true"]) {
    &,
    & * {
      opacity: 0 !important;
      pointer-events: none !important;
      visibility: hidden !important;
    }
  }

  &[data-should-slide="true"] {
    @extend .__use-megamenu-inTransition;
    transition-delay: 0ms;
  }

  &::before {
    @apply absolute inset-0 bg-black/5 -z-1;
    // content: "";
  }

  &-container-wrapper1 {
    @apply p-4;
  }

  &-container {
    @apply relative grid grid-cols-1 grid-rows-1 rounded-b-xl w-full min-w-[30rem] h-fit max-h-full pointer-events-none isolate;

    transition: inherit;
    transition-delay: inherit;
    margin-top: calc(var(--mt, 96) * 1px);
    left: calc(var(--left, 0) * 1px);
  }

  &-arrow {
    @extend .__use-megamenu-inTransition;
    @apply absolute bottom-full aspect-square pointer-events-auto;

    z-index: 1;
    border-width: 0.85em;
    border-color: #ffffff;
    border-bottom-color: transparent;
    border-right-color: transparent;
    bottom: 100%;
    left: calc(var(--anchor-left, 0) * 1px);
    box-shadow: -6px -7px 8px #00000010;
    transform: rotate(45deg)
      translateY(calc(var(--arrow-translate-y, 100) * 1%));
  }

  &-wrapper {
    @extend .__use-megamenu-inTransition;
    @apply absolute bg-white -z-1 pointer-events-auto;

    box-shadow: 0px 0px 12px #00000029;
    border-radius: inherit;
    width: calc(var(--width, 100%) * 1px);
    height: calc(var(--height, 100%) * 1px);
  }

  &-section {
    @extend .__use-megamenu-outTransition;
    @apply relative overflow-hidden bg-white z-1 grid row-start-1 row-span-1 col-start-1 col-span-1 w-full h-fit max-w-full pointer-events-auto;
    border-radius: inherit;
    box-shadow: 0px 10px 10px #00000038;

    &:not([data-status="active"]) {
      @apply opacity-0 pointer-events-none;
    }
  }

  &-row {
    @extend .__use-megamenu-inTransition;
    @apply flex flex-row flex-nowrap overflow-auto;
    border-radius: 0.4em;
  }

  .help-and-advice-section {
    .megamenu-row {
      @apply max-h-full;
    }
  }

  &-col {
    @extend .__use-megamenu-outTransition;
    @apply isolate sticky p-0 flex-1 [&:not(:last-child)]:border-r [&:not(:last-child)]:border-slate-300;

    &[data-transform-axe="x"] {
      left: calc(var(--transform-offset, 0) * 5%);
    }

    &[data-transform-axe="y"] {
      top: calc(var(--transform-offset, 0) * 5%);
    }

    &__stack {
      @apply w-full h-full flex flex-col flex-wrap gap-2;
    }

    > .grid {
      @apply h-full;
    }
  }

  &-category {
    display: inherit;
    flex-direction: inherit;
    flex-wrap: inherit;
    max-height: inherit;
  }

  &-item {
    @apply flex flex-shrink-0 flex-row items-center outline-none max-w-xl;

    p {
      white-space: wrap !important;
    }

    &[data-type="RippleBox"] {
      @apply select-none;

      &:hover,
      &:focus-visible {
        p {
          color: white;
        }

        img {
          transition: filter 240ms ease;
          filter: brightness(0) invert(1);
        }
      }
    }

    &.contact-items {
      img {
        @apply w-4 h-4 max-w-[1rem] max-h-[1rem] mt-0;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
    background-color: transparent;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: rgb(0 0 0 / 10%);
      border-radius: 1em;
      box-shadow: 0 0 0 0.1em white inset;

      &:hover {
        background-color: rgb(0 0 0 / 15%);
      }
    }
  }

  &-backdrop {
    @apply absolute w-full h-screen;

    &:not([data-is-active="true"]) {
      @apply hidden;
    }
  }
}
</style>
