<template>
  <div
    class="megamenu-contact-us mx-8 my-10"
  >
    <CommonLiveChatBlock position="menu"></CommonLiveChatBlock>
  </div>
</template>

<script setup lang="ts">
</script>
