<template>
  <div
    class="megamenu-section"
    :data-status="isActive ? 'active' : 'inactive'"
    :data-anchor-key="props.anchorKey"
    :style="{
      '--transform-offset': sectionTransformOffset,
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { MegaMenuAnchorKey } from "./index.vue";

export interface MegaMenuSectionProps {
  anchorKey: MegaMenuAnchorKey;
  activeSection: MegaMenuAnchorKey | undefined;
}

const props = withDefaults(defineProps<MegaMenuSectionProps>(), {
  active: false,
  activeSection: undefined,
});

const isActive = computed<boolean>(
  () => props.activeSection === props.anchorKey
);

// Helpers
const sectionTransformOffset = useEffect(() => {
  const sectionAnchors = Array.from(
    document.querySelectorAll<HTMLLIElement>(
      `header ul li[data-megamenu-anchor-key]`
    )
  );
  if (props.activeSection === undefined || sectionAnchors.length <= 0) return 0;

  const sectionPosition = sectionAnchors.findIndex(
    (x) => x.getAttribute("data-megamenu-anchor-key") === props.anchorKey
  );

  return (
    sectionPosition -
    sectionAnchors.findIndex(
      (x) => x.getAttribute("data-megamenu-anchor-key") === props.activeSection
    )
  );
}, [isActive]);
</script>
