<template>
  <div
    class="megamenu-col"
    :class="{
      'top-0': !!props.sticky,
    }"
    :data-transform-axe="props.transformAxe"
    :style="{ 'border-right-width': props.hideDivider ? 0 : undefined }"
  >
    <slot name="title" />
    <div
      :class="{
        'megamenu-col__stack': props.layout === 'auto',
        grid: props.layout === 'categories',
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
export interface MegaMenuColumnProps {
  transformAxe?: "x" | "y";
  sticky?: boolean;
  hideDivider?: boolean;
  layout?: "categories" | "auto";
}

const props = withDefaults(defineProps<MegaMenuColumnProps>(), {
  transformAxe: "x",
  sticky: false,
  hideDivider: false,
  layout: "auto",
});
</script>
